<template>
  <el-dialog
      :title="'添加'+title+'标签'"
      width="35%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 20%" ref="form">
      <el-row>
        <el-col :span="15">
          <el-form-item label="标签名称" prop="labelName">
            <el-input v-model="form.labelName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addLabelCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "label-add",
  data() {
    return {
      title:this.labelType===1?"客资":"款式",
      form: {
        //公司名
        labelName: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        labelName: [
          {required: true, message: '请输入标签名称', trigger: 'change'},
        ],
      },
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    labelType:{
      type: Number,
      required: true,
    }
  },
  created() {
  },
  methods: {
    //检查订单校验
    addLabelCheck: function () {
      console.log(this.form)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addLabel();
        }
      })
    },
    //添加店铺
    addLabel: function () {
      let value = "是否添加该"+this.title+"标签?"
      this.$confirm(value, '添加标签:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/label/addLabel",
          params: {
            labelName:this.form.labelName,
            tenantCrop:this.form.tenantCrop,
            labelType:this.labelType
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("添加成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>